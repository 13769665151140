import { template as template_36f40f94905e44a7a74953ce38b60911 } from "@ember/template-compiler";
const FKLabel = template_36f40f94905e44a7a74953ce38b60911(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
