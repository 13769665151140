import { template as template_a73d6516a4cf4c1fafa00c743dc5b6a8 } from "@ember/template-compiler";
const SidebarSectionMessage = template_a73d6516a4cf4c1fafa00c743dc5b6a8(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
