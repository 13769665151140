import { template as template_a9fd41e3c21b4de18f1f57867b777fb8 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
export default class RenderGlimmerContainer extends Component {
    @service
    renderGlimmer;
    static{
        template_a9fd41e3c21b4de18f1f57867b777fb8(`
    {{~#each this.renderGlimmer._registrations as |info|~}}
      {{~#in-element info.element insertBefore=null~}}
        <info.component
          @data={{info.data}}
          @setWrapperElementAttrs={{info.setWrapperElementAttrs}}
        />
      {{~/in-element~}}
    {{~/each~}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
