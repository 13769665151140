import { template as template_ab510181874a483cbabf93b643c16ab8 } from "@ember/template-compiler";
const FKText = template_ab510181874a483cbabf93b643c16ab8(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
