import { template as template_951b93d951ea41dda9e213641a9b8e2e } from "@ember/template-compiler";
const FKControlMenuContainer = template_951b93d951ea41dda9e213641a9b8e2e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
